/* Google Font Link */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

.container-parent {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(90deg, rgba(201, 213, 233, 1) 0%, rgba(224, 230, 245, 1) 42%, rgba(190, 208, 232, 1) 54%);
  padding: 30px;
}

.loginalaimg {
  width: 100%;
  height: 480px;
  margin: auto;
  position: relative;
}

.loginalaimg img {
  width: 100%;
  height: 100%;
}

.lgnadivld {
  width: 100%;
  max-width: 60%;
}

.divfrmabs {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  width: 50%;
}

.forms {
  padding: 9px 15px;
  height: 480px;
}

.containerLand {
  position: relative;
  max-width: 850px;
  width: 100%;
  background: #fff;
  padding: 24px 30px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  perspective: 2700px;
}

.containerLand .cover {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  width: 50%;
  z-index: 98;
  transition: all 1s ease;
  transform-origin: left;
  transform-style: preserve-3d;
}

.containerLand #flip:checked~.cover {
  transform: rotateY(-180deg);
}

.containerLand .cover .front,
.containerLand .cover .back {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cover .back {
  transform: rotateY(180deg);
  /* backface-visibility: hidden; */
}

.containerLand .cover::before,
.containerLand .cover::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 100%;
  background: #7d2ae8;
  opacity: 0.5;
  z-index: 12;
}

.containerLand .cover::after {
  opacity: 0.3;
  transform: rotateY(180deg);
  /* backface-visibility: hidden; */
}

.containerLand .cover img {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: 10;
}

.containerLand .cover .text {
  position: absolute;
  z-index: 130;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cover .text .text-1,
.cover .text .text-2 {
  font-size: 26px;
  font-weight: 600;
  color: #fff;
  text-align: center;
}

.cover .text .text-2 {
  font-size: 15px;
  font-weight: 500;
}

.containerLand .forms {
  height: 100%;
  width: 100%;
  background: #fff;
}

.containerLand .form-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .form-content .login-form,
.form-content .signup-form {
  width: calc(100% / 2 - 25px);
} */

/* .forms .form-content .title{
  position: relative;
  font-size: 24px;
  font-weight: 500;
  color: #333;
} */
/* .forms .form-content .title:before{
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 25px;
  background: #7d2ae8;
} */
.forms .signup-form .title:before {
  width: 20px;
}

.forms .form-content .input-boxes {
  margin-top: 15px;
}


.forms .form-content .input-box {
  display: flex;
  align-items: center;
  height: 50px;
  width: 100%;
  margin: 10px 0;
  position: relative;
}

.form-content .input-box input {
  height: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #00000046;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 500;
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.2); */
  transition: all 0.3s ease;
  border-radius: 10px;
}

.form-content .input-box input:focus,
.form-content .input-box input:valid {
  border-color: #7d2ae8;
}

.form-content .input-box i {
  position: absolute;
  color: #00000073;
  font-size: 15px;
  margin-left: 9px;
}

.inputboxkey i {
  position: absolute;
  color: #00000073;
  font-size: 15px;
  transform: rotate(223deg);
}

.forms .form-content .text {
  font-size: 14px;
  font-weight: 500;
  color: #f0bc55;
}

.forms .form-content .text a {
  color: #f0bc55;
}

.rmdirfgdiv {
  display: flex;
  justify-content: space-between;
}

.forms .form-content .text a {
  text-decoration: none;
}

.forms .form-content .text a:hover {
  text-decoration: underline;
}

.forms .form-content .button {
  color: #fff;
  margin-top: 15px;
}

.forms .form-content .button input {
  color: #fff;
  background: #f0bc55;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.4s ease;
  border: none;
}

.forms .form-content .button input:hover {
  background: #f0bc55;
}

.forms .form-content .checkdivbox label {
  color: #f0bc55;
  cursor: pointer;
  margin-left: 9px;
}

.sgnuptct {
  color: #f0bc55;
  font-size: 14px;
  font-weight: 600;
}

.sccghdnttxt {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
}

.dljkflsxsosgn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%
}

.exptfflx {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2%;
  padding-top: 10px;

}

.ecxpkkhubimg {
  width: 45px;
  height: 41px;
}

.ecxpkkhubimg img {
  width: 100%;
  height: 100%;
}

.eperitxt {
  font-size: 15px;
  color: #230707e3;
  font-weight: 600;
  font-family: Noirprolight;
}

.forms .form-content label:hover {
  text-decoration: underline;
}

.forms .form-content .login-text,
.forms .form-content .sign-up-text {
  text-align: center;
  margin-top: 25px;
}

.containerLand #flip {
  display: none;
}

@media (max-width: 730px) {
  .containerLand .cover {
    display: none;
  }

  .form-content .login-form,
  .form-content .signup-form {
    width: 100%;
  }

  .form-content .signup-form {
    display: none;
  }

  .containerLand #flip:checked~.forms .signup-form {
    display: block;
  }

  .containerLand #flip:checked~.forms .login-form {
    display: none;
  }
}


/*shiltu css**/

.loginsection_wrap {
  width: 100%;
  max-width: 60%;
  height: auto;
  margin: auto;
  background-color: #fff;
  border-radius: 25px;
  overflow: hidden;
  box-shadow: 0px 0px 5px 0px #2c2c2ccc;
}

.loginsection_wrap .loginimg_wrap {
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 5px 0px #949494cc;
  border-radius: 25px;
}

.loginsection_wrap .loginimg_wrap img {
  width: 100%;
  height: 100%;
}

.loginsection_wrap .logcontent_wrap {
  width: 100%;
  height: auto;
  padding: 20px 30px 20px 20px;
}

.loginsection_wrap .logogo_icon {
  width: 100%;
  max-width: 90px;
  height: auto;
  overflow: hidden;
  margin-bottom: 15px;
}

.loginsection_wrap .logogo_icon img {
  width: 100%;
  height: 100%;
}

.loginsection_wrap .heading {
  font-size: 18px;
  text-align: left;
  font-weight: 700;
  color: #000000;
  padding: 0;
  margin-bottom: 30px;
}

.loginsection_wrap form .form-group {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.loginsection_wrap form .form-group label {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  color: #000000;
  padding: 0;
  margin-bottom: 1px;
}

.loginsection_wrap form .form-group .form-control {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: 2px solid #543b8e;
  background-color: transparent !important;
  outline: 0;
  text-decoration: none;
  box-shadow: none;
}

.loginsection_wrap form .form-group .form-control:focus {
  outline: 0;
  box-shadow: none;
}

.loginsection_wrap form .form-group input:-internal-autofill-selected {
  background-color: transparent !important;
}

/**checkbox_area*/

.loginsection_wrap .checkbox_area {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.checkbox_area .form-group {
  display: block;
  margin-bottom: 2px !important;
}

.checkbox_area .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox_area .form-group label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px !important;
  font-size: 12px !important;
}

.checkbox_area .form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 0.5px solid #543b8e;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 4.5px;
}

.checkbox_area .form-group input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid #543b8e;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.loginsection_wrap .submitarea_wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
}

.submitarea_wrap .login_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
  border: none;
  color: #fff;
  width: auto;
  height: auto;
  padding: 6px 20px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
}

.submitarea_wrap .forgot_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  cursor: pointer;
  border: double 2px transparent;
  background-origin: border-box;
  background-image: rgb(76, 59, 146);
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #ed3b4b, #4c3b92);
  background-clip: padding-box, border-box;
  -webkit-background-clip: padding-box, border-bo;
  /* background: transparent; */
  color: #000000;
  width: auto;
  height: auto;
  padding: 4px 15px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginsection_wrap .advicetext {
  width: 100%;
  height: auto;
  margin-bottom: 2px;
  margin-top: auto
 
}

.loginsection_wrap .advicetext p {
  font-size: 12px;
  font-weight: 600;
  color: #000000;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.loginsection_wrap .bottomlogo {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: end;
}

.loginsection_wrap .bottomlogo .imgwrap {
  width: 100px;
  height: auto;
  overflow: hidden;
}

.loginsection_wrap .bottomlogo .imgwrap img {
  width: 100%;
  height: 100%;
}

.inputcompanydtls {
  padding-bottom: 15px;
  position: relative;
  width: 100%;
}
.Hideimgdivicn{
  max-width: 20px;
  min-width: 20px;
  position: absolute;
  right: 2px;
  top: 3px;
  width: 100%;
}
  .Hideimgdivicn img{
    width: 100%;
    height: 100%;
    vertical-align: middle;
  border-style: none;

  
  }


@media (max-width: 768px) {

  .loginsection_wrap {
    width: 100%;
    max-width: 90%;
    height: auto;
  }

  .loginsection_wrap .loginimg_wrap {
    display: none;
  }

  .responsivelogogo_design {
    width: 100%;
    height: auto;
    padding: 50px 20px;
    background: linear-gradient(138deg, rgba(237, 59, 76, 1) 0%, rgba(113, 48, 160, 1) 73%);
    border-radius: 15px;
    /* margin-bottom: 15px; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .responsivelogogo_design .logogo_icon {
    width: 100px;
    height: auto;
    border-radius: 50%;
    padding: 15px;
    background-color: #fff;
    margin-bottom: 0;
  }

  .loginsection_wrap .logcontent_wrap {
    width: 100%;
    height: auto;
    padding: 0;
  }

  .loginsection_wrap .rightcontentbox {
    padding: 20px 30px 20px 20px;
  }
}

@media (max-width: 576px) {
  .loginsection_wrap {
    width: 100%;
    max-width: 95%;
    height: auto;
  }
}

@media (max-width: 480px) {
  .loginsection_wrap {
    width: 100%;
    max-width: 99%;
    height: auto;
  }

  .submitarea_wrap .login_btn {
    font-size: 13px;
    margin-right: 15px;
  }

  .submitarea_wrap .forgot_btn {
    font-size: 13px;
  }
}   