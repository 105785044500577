*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: #2C3659;
  background-color: #F6F7FA;
  margin: 0 auto;
}

body.drakmode {
  color: #fff;
  background-color: #000;
}

a {
  color: #2C3659;
}

body.drakmode a {
  color: #fff;
}

/* 
=============
Heading Tag
=============
*/
h1,
h2,
h3,
h4,
h5.h6,
p {
  margin: 0;
}



/* 
=============
Sidebar
=============
*/
.sidebarwrap {
  display: flex;
  width: 310px;
  height: 100vh;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  /* background-color: #fff; */
  background-image: url(./Images/sidebarbg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 5px 0px #d5d3d3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: .6s;
}

.sidebarwrap::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000075;
  z-index: 1;
}

.sidebarwrap .top_area {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.sidebarwrap .sidebar_menu {
  position: relative;
  z-index: 2;
}

#backbtnsidebar {
  display: none;
  border: 1.5px solid #F5F5F5;
  background-color: transparent;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  box-shadow: none;
  height: 45px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
  color: #fff;
}

.logo_area {
  width: 100%;
  max-width: 262px;
  min-width: 262px;
  height: 70px;
  border-radius: 10px;
  /* border: 1.5px solid #F5F5F5; */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_area .dropdown {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo_area .logo {
  width: 110px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.logo_area .logo img {
  width: 100%;
  height: 100%;
}

.logo_area .dropdown a {
  text-decoration: none;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.logo_area .dropdown .dropdown-item:hover {
  background-color: #e6e6e6;
}

.logo_area .dropdown .dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}

.logo_area .dropdown-toggle::after {
  display: inline-block;
  margin-left: 15px;
  vertical-align: 0.255em;
  content: "\f078";
  border: none;
  font-family: "Font Awesome 5 Free";
}

.sidebar_menu {
  width: 100%;
  max-height: 575px;
  overflow-y: auto;
}

.sidebar_menu::-webkit-scrollbar {
  display: none;
}

.sidebar_menu .Menu_Nav {
  width: 100%;
  height: auto;
  padding: 49px 0;
}

.sidebar_menu .Menu_Nav .menuline {
  width: 100%;
  height: auto;
}

.sidebar_menu .nav a {
  list-style: none;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  width: 100%;
  height: 40px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 5px 14px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  opacity: 0.75;
  transition: 0.6s;
  cursor: pointer;
}

.sidebar_menu .nav a:hover {
  color: rgb(236, 64, 122);
  font-weight: 600;
  font-size: 18px;
}

.sidebar_menu .nav a.active {
  color: rgb(236, 64, 122);
  font-weight: 600;
  font-size: 18px;
}

.sidebar_menu .nav a i {
  margin-right: 15px;
}

.sidebar_menu .Menu_Nav .Submenu {
  width: 100%;
  height: auto;
  padding-left: 30px;
  list-style: none;
}

.sidebar_menu .Menu_Nav .Submenu a {
  text-decoration: none;
  width: 100%;
  height: 25px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  opacity: 0.75;
  transition: 0.6s;
  cursor: pointer;
}

.sidebar_menu .Menu_Nav .Submenu a:hover {
  color: #f7e943;
  font-weight: 600;
  font-size: 14px;
}

.sidebar_menu .Menu_Nav .Submenu a.active {
  color: #f7e943;
  font-weight: 600;
  font-size: 14px;
}

/***/

/* 
=============
Header
=============
*/
.mainheader_sec {
  width: calc(100% - 310px);
  margin-left: auto;
  height: 80px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 3px 0px 5px 0px #d5d3d3;
}

body.drakmode .mainheader_sec {
  background-color: #000;
  box-shadow: 1px 0px 5px 0px #F5F5F5;
}

.responsive_menu {
  display: none;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  padding: 0;
  margin: 0 20px 0 0;
  border: none;
}

body.drakmode .responsive_menu {
  color: #fff;
}

.Search_box {
  display: block;

}


.Search_box .form-inline {
  position: relative;
  width: 333px;
  height: 48px;
  border-radius: 10px;
}

.Search_box .form-inline input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 2px 10px 2px 40px;
  background: #EFF2F3;
}

.Search_box .Search_icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.responsSearch_btn {
  background: #EFF2F3;
  text-decoration: none;
  border: none;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: auto;
  display: none;
}

.responsiveSearch_box .form-inline {
  position: fixed;
  width: 95%;
  height: 48px;
  border-radius: 10px;
  z-index: 9;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px 0px #958f8f80;
  overflow: hidden;
}

.responsiveSearch_box .form-inline input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 2px 50px 2px 10px;
  /* background: #EFF2F3; */
  background: #fff;
}

.responsiveSearch_box .Search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background: transparent;
}



.mainheader_sec .rightcontent {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
}

.mainheader_sec .actionBtn_wrap {
  display: flex;
  align-items: center;
}

.Createpost_btn .btn {
  border-radius: 10px;
  background: #2C3659;
  display: flex;
  width: 151px;
  height: 46px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Createpost_btn .btn i {
  margin-right: 10px;
}

.notification_btn {
  position: relative;
}

.notification_btn .active {
  position: absolute;
  top: 0px;
  right: 28px;
  z-index: 2;
}

.notification_btn .active i {
  font-size: 8px;
  color: rgb(180, 2, 2);
}

.notification_btn .btn {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  margin-right: 20px;
}

.notification_btn .btn i {
  font-size: 24px;
}

.Accountdetails {
  display: flex;
  align-items: center;
  width: 230px;
  position: relative;
}

.Accountdetails .profile_pic {
  width: 42px;
  min-width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.Accountdetails .profile_pic img {
  width: 100%;
  height: 100%;
}

.Accountdetails .namearea .dropdown a {
  text-decoration: none;
  display: block;
  position: relative;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.Accountdetails .namearea .dropdown a strong {
  display: block;
}

.Accountdetails .namearea .dropdown a span {
  display: block;
  opacity: 0.75;
}

.Accountdetails .namearea .dropdown-toggle::after {
  display: inline-block;
  margin-left: 15px;
  vertical-align: 0.255em;
  content: "\f078";
  border: none;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(-50%, -50%);
}

.Accountdetails .namearea .dropdown-menu {
  box-shadow: 0px 0px 5px 0px #ccc;
}

.Accountdetails .namearea .dropdown-menu a {
  color: #2C3659;
  font-weight: 500;
}

.Accountdetails .namearea .dropdown-menu a i {
  opacity: 0.7;
}

/* 
=============
Dashboard
=============
*/

.main_wrap {
  width: calc(100% - 310px);
  height: auto;
  margin-left: auto;
  margin-top: 80px;
  padding: 0px 20px 30px;
}

/*----=== login  ===----*/

.login-main-div {
  height: 100vh;
}

.pass_input {
  position: relative;
}

.eye_icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  cursor: pointer;

}

.custom-header-class {
  background-color: #2C3659;
}





/* 
=============
Responsive
=============
*/

@media (max-width: 1400px) {

  .sidebarwrap {
    width: 280px;
  }

  .mainheader_sec {
    width: calc(100% - 280px);
  }

  .main_wrap {
    width: calc(100% - 280px);
    padding: 0px 20px 30px;
  }

  .logo_area {
    min-width: 170px;
    height: 60px;
  }

}

@media (max-width: 1199px) {
  .mainheader_sec .rightcontent {
    width: 55%;
  }

  .Search_box .form-inline {
    width: 280px;
  }

  .activityprogress .boxwrap {
    height: 120px;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 10px;
    top: 32%;
  }
}

@media (max-width: 991px) {
  .sidebarwrap {
    width: 280px;
    /* position: absolute; */
    top: 0;
    left: -300px;
    height: 100%;
  }

  .sidebarwrap.show {
    left: 0px;
  }

  #backbtnsidebar {
    display: flex;
  }

  .mainheader_sec {
    width: 100%;
  }

  .main_wrap {
    width: 100%;
  }

  .responsive_menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .Accountdetails .namearea .dropdown a strong {
    display: none;
  }

  .Accountdetails .namearea .dropdown a span {
    display: none;
  }

  .Accountdetails {
    width: 42px;
    position: relative;
  }

  .Accountdetails .profile_pic {
    margin-right: 0;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 0;
    opacity: 0;
  }

  .mainheader_sec .rightcontent {
    width: 38%;
  }

  .Createpost_btn .btn {
    height: 36px;
    font-size: 10px;
    width: 100px;
    margin-right: 10px;
  }

  .Search_box {
    display: none;
  }

  .responsSearch_btn {
    display: block;
  }

  .mainheader_sec {
    height: 80px;
    padding: 10px 20px;
  }

  .main_wrap {
    margin-top: 70px;
  }



}

@media (max-width: 600px) {
  .mainheader_sec .rightcontent {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .mainheader_sec .rightcontent {
    width: 65%;
  }

  .main_wrap {
    padding: 0px 10px 30px;
  }

  .activityprogress .boxwrap {
    height: 75px;
  }

  .notification_btn .btn {
    width: 30px;
    height: 30px;
  }

  .notification_btn .btn i {
    font-size: 16px;
  }

  .notification_btn .active {
    top: -6px;
    right: 25px;
  }

  .Accountdetails {
    width: 32px;
  }

  .logo_area {
    height: 40px;
    min-width: 140px;
  }

  .sidebarwrap {
    width: 250px;
  }

}

.form-header {
  width: 78%;
  padding: 4px 6px;
  margin-top: 89px;
}

.datatable-view {
  padding-left: 321px;
  padding-right: 10px;
}

.form-page-headline {
  text-align: "center";
  font-size: "30px";
  color: "#868e96";
  margin: "35px";
  font-weight: "bold"
}

.floating-message {



  /* background-color: rgba(22, 150, 18, 0.7); */
  /* color: rgb(252, 252, 252); */
  padding: 4px;
  border-radius: 4px;
  width: 64px;
  display: flex;
  justify-content: center;
}

.change-designation {
  padding: 4px;
  border-radius: 4px;
  width: 110px;
  display: flex;
  justify-content: center;
  font-size: 12px;

}

@keyframes blink {
  0% {
    color: red;
  }

  50% {
    color: black;
  }

  100% {
    color: red;
  }
}

.edit-tag {
  animation: blink 1s infinite;
}



.btn-grad {
  background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
  /* margin: 10px; */
  /* padding: 15px 45px; */
  text-align: center;
  transition: 0.9s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.btn-grad-cancel {
  background-image: linear-gradient(to right, #ce0202 0%, #ff0101 51%, #fdfeff 100%);
  /* margin: 10px; */
  /* padding: 15px 45px; */
  text-align: center;
  transition: 0.9s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-left: 1px;
}


.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  background-size: 400%;
  animation: glowing 20s linear infinite;
  text-decoration: none;
}

/* dashboard icon animation */
/* Add the 'animate__animated' class for general animation settings */
.icon-1 {
  transition: transform 0.1s ease-in-out;
}

/* Define the bounce animation using @keyframes */
@keyframes bounce {

  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-10px);
  }
}

/* Apply the bounce animation on icon-1 class when hovering over the icon class */
.icon:hover .icon-1 {
  animation: bounce 1s infinite;
}

.addMoreBorder {
  border: 1px solid #939393;
  padding: 25px;
  margin-top: 37px;
}

.chatBotButton {
  height: 28px;
  padding: 3px 9px;
  font-size: 14px;
}

/* .nav a:focus {
    background-color: rgb(243, 243, 243);
    color: rgb(241, 7, 7);
    outline: none; 
    width: 75%;
  }
  */
/* Add this CSS in your styles or CSS file */
/* .nav .icon:focus {
  background-color: red;
  color: white; 
} */
/* .icon3:focus {
  background-color: red;
  color: rgb(200, 216, 157);
  outline: none; 
} */
.three h5 {
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.5em;
  padding-bottom: 5px;
  position: relative;
}

.three h5:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 5px;
  width: 45px;
  background-color: #060881;
}

.three h5:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  height: 1px;
  width: 105%;
  max-width: 355px;
  background-color: #091974;
}

.addExcelInput {
  position: absolute;
  width: 113px;
  height: 39px;
  opacity: 0;
  cursor: pointer;
}
.mtclose{
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
}
.ReactModal__Overlay{
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgba(145, 132, 132, 0.75) !important;
  z-index: 999;
  
  }
  .ReactModal__Content{
    width: 900px;
    height: 500px;
    background-color: #250202;
    border-radius: 10px;
    margin: 0 auto;
    overflow-y: auto;
    inset: auto !important;
    position: relative !important;
  }
  .mtclose{
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 25px;
    cursor: pointer;
  }
