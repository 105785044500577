.createeventdetails {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  right: 0px;
  background-color: #0000008a;
  z-index: 22;
  }

  .detailspagecontainer {
    width: 100%;
    max-width: 84%;
    margin: 0 auto;
  }

  .createdivfrmmain {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    padding: 30px 25px;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 600px;
    min-width: 600px;
  }

  .trainingpageleftdivmain {
    padding-bottom: 10px;
  }

  .rsntxrpnewtxt {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: noirprolight;
    color: #000;
  }

  .typstrningwhselctnew {
    width: 100%;
    padding: 17px 15px;
    border-radius: 4px;
    margin: 10px 0px 10px 0px;
    border: none;
    box-shadow: 0px 4px 9px 0px #ccc;
  }